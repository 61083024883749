import { v4 as uuidv4 } from 'uuid';
import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import CustomStore from 'devextreme/data/custom_store';
import { HttpClient } from '@angular/common/http';
import {
  catchError,
  map,
  of,
  startWith,
  Observable,
  lastValueFrom,
  tap,
} from 'rxjs';
import { HttpRequestState } from './core/interceptors/http';
import { environment } from 'src/environments/environment';
import DataSource from 'devextreme/data/data_source';

const API_ENDPOINT = `${environment.BACKEND_URL}/data/kunden`;

@Injectable({
  providedIn: 'root',
})
export class KundenService {
  public customerList!: DataSource;
  private customStore!: CustomStore<Array<KundeList>, number>;

  constructor(public httpClient: HttpClient) {
    this.InitCustomStoreList();
  }

  public CreateCustomer(oelisId: number): Observable<HttpRequestState<Kunde>> {
    return this.httpClient.get<Kunde>(`${API_ENDPOINT}/create/${oelisId}`).pipe(
      map((value) => ({ isLoading: false, data: value })),
      catchError((error) => of({ isLoading: false, error })),
      startWith({ isLoading: true })
    );
  }

  public ChangeCustomer(obj: {
    customerId: number;
    oelisId: number;
    keepObjectNr: boolean;
  }): Observable<HttpRequestState<Kunde>> {
    return this.httpClient
      .post<Kunde>(`${API_ENDPOINT}/${obj.customerId}/changecustomer`, obj)
      .pipe(
        map((value) => ({ isLoading: false, data: value })),
        catchError((error) => of({ isLoading: false, error })),
        startWith({ isLoading: true })
      );
  }

  public ChangeCustomerStatus(obj: {
    customerId: number;
    customerStatus: number;
  }): Observable<HttpRequestState<Kunde>> {
    return this.httpClient
      .get<Kunde>(
        `${API_ENDPOINT}/${obj.customerId}/changestatus/${obj.customerStatus}`
      )
      .pipe(
        map((value) => ({ isLoading: false, data: value })),
        catchError((error) => of({ isLoading: false, error })),
        startWith({ isLoading: true })
      );
  }

  public GetCustomer$(id: number): Observable<HttpRequestState<Kunde>> {
    return this.httpClient.get<Kunde>(`${API_ENDPOINT}/${id}`).pipe(
      map((value) => ({ isLoading: false, data: value })),
      catchError((error) => of({ isLoading: false, error })),
      startWith({ isLoading: true }),
      tap((x) => console.log('Data Kunde', x))
    );
  }

  public GetCustomerQuickview$(
    id: number
  ): Observable<HttpRequestState<KundeQuickview>> {
    return this.httpClient
      .get<KundeQuickview>(`${API_ENDPOINT}/${id}/quickview`)
      .pipe(
        map((value) => ({ isLoading: false, data: value })),
        catchError((error) => of({ isLoading: false, error })),
        startWith({ isLoading: true })
      );
  }

  public SaveCustomer$(
    customerData: Kunde
  ): Observable<HttpRequestState<Kunde>> {
    return this.httpClient
      .put<Kunde>(`${API_ENDPOINT}/${customerData._id}`, { ...customerData })
      .pipe(
        map((value) => ({ isLoading: false, data: value })),
        catchError((error) => of({ isLoading: false, error })),
        startWith({ isLoading: true })
      );
  }

  public GetCustomerStats$(
    customerId: number
  ): Observable<HttpRequestState<CustomerLastQuotes[]>> {
    return this.httpClient
      .get<CustomerLastQuotes[]>(`${API_ENDPOINT}/${customerId}/lastquotes`)
      .pipe(
        map((value) => ({ isLoading: false, data: value })),
        catchError((error) => of({ isLoading: false, error })),
        startWith({ isLoading: true })
      );
  }

  private InitCustomStoreList(): void {
    this.customStore = new CustomStore<Array<KundeList>, number>({
      key: '_id',
      load: (options: LoadOptions<KundeList[]>) => {
        console.log('Load Options', options);

        return lastValueFrom(
          this.httpClient.post(`${API_ENDPOINT}/list`, options)
        );
      },
      byKey: (key: number) => {
        console.log(key);
        return new Promise((res) => res);
      },
    });

    this.customerList = new DataSource<Array<KundeList>, number>({
      store: this.customStore,
    });
  }
}

export type KundeList = {
  _id: number;
  _gid: string;
  knr: string;
  objektnr: number;
  firma: string;
  kname: string;
  vorname: string;
  plz: string;
  ort: string;
  strasse: string;
  tour: number;
  tour_id?: number;
  status_id: number;
};

export type KundenPausierer = {
  _id: number;
  _created_at?: Date;
  _updated_at?: Date;
  _gid: string;
  _created_by?: number;
  _updated_by?: number;
  _version?: number;
  _mandant_id?: number;
  von: Date | null | undefined;
  bis: Date | null | undefined;
  grund: string;
};

export const initialKundenPausierer: KundenPausierer = {
  _id: 0,
  _gid: uuidv4(),
  von: null,
  bis: null,
  grund: '',
};

export type KundenWarenTraeger = {
  _id: number;
  _created_at?: Date;
  _updated_at?: Date;
  _gid: string;
  _created_by?: number;
  _updated_by?: number;
  _version?: number;
  seriennr: string;
  lieferdatum?: Date | null | undefined;
  kunden_id?: number;
  warentraeger_id: number | null;
};

export const initialKundenWarenTraeger: KundenWarenTraeger = {
  _id: 0,
  _gid: uuidv4(),
  seriennr: '',
  warentraeger_id: null,
  lieferdatum: null,
};

export type SepaMandat = {
  _id: number;
  _gid: string;
  signdate?: Date | null | undefined;
  valdate?: Date | null | undefined;
};

export const initialSepaMandat: SepaMandat = {
  _id: 0,
  _gid: uuidv4(),
  signdate: null,
  valdate: null,
};

export type Kunde = {
  _id: number;
  _created_at: Date;
  _updated_at: Date;
  _gid: string;
  _mandant_id: number;
  _version: number;
  _created_by: null;
  _updated_by: null;
  knr: string;
  debit: string;
  lekkerland: string;
  firma: string;
  vorname: string;
  kname: string;
  plz: string;
  ort: string;
  strasse: string;
  telefon: string;
  provi: number;
  provinf: number;
  provisat: number;
  mg: string;
  tour: number;
  nfk: string;
  tanknr: string;
  kontonr: string;
  blz: string;
  skonto: number;
  zahlart: string;
  zahlartnf: string;
  llndl: number;
  fax: string;
  mgprov: boolean | undefined | null;
  mgprovnf: string;
  eindat: Date;
  vlt: string;
  aenderung: Date;
  objektnr: number;
  wt1: string;
  wt2: string;
  wt3: string;
  tel2: string;
  mobil: string;
  bemerk: string;
  sp: boolean | undefined | null;
  spwert: number;
  email: string;
  www: string;
  bonus: number;
  apartner: string;
  bonustart: null;
  nl: string;
  oeffnung: string;
  shopqm: number;
  gesellsch: string;
  user: string;
  new: boolean;
  adm: string;
  hbwid: number;
  hbwidnam: string;
  lkz: string;
  uid: string;
  vip: boolean;
  dbemerk: string;
  fbemerk: string;
  varfilter: string;
  insammler: boolean;
  istsammler: boolean;
  sammlerknr: string;
  unter: string;
  staffel: boolean | undefined | null;
  nfkat: string;
  provision_id: number | null;
  tour_id: number | null;
  zahlart_id: number | null;
  servicepauschale_id: number | null;
  submandant_id: number | null;
  objekt_id: number | null;
  status_id: number;
  tax_country_id: number | null;
  warentraeger: Array<KundenWarenTraeger>;
  service_tour_id: number | null;
  sepamandate: Array<SepaMandat>;
  notizen: Array<unknown>;
  invoice_email: string;
  preisgruppe_id: number | null;
  dispo_control: boolean;
  lat?: number;
  lon?: number;
  hasinvoiceadr: boolean;
  iv_zeile1: string;
  iv_strasse: string;
  iv_plz: string;
  iv_ort: string;
  delivery_turn: number;
  mg_prov_id?: number | null;
  drive_deliveryproof: boolean;
  drive_comment: string;
  costcenter_id?: number | null;
  pausierer?: KundenPausierer;
};

export type CustomerLastQuotes = {
  datum: Date;
  umsatz_nonfood: number;
  umsatz_flower: number;
  vkwert_nonfood: number;
  vkwert_flower: number;
  avq_flower: number;
  avq_nonfood: number;
  avq: number;
};

export const DeliveryType = [
  { _id: 1, name: 'Standard' },
  { _id: 2, name: 'EFH' },
  { _id: 3, name: 'Event' },
];

export type KundeQuickview = {
  _id: number;
  knr: string;
  kname: string;
  firma: string;
  strasse: string;
  plz: string;
  ort: string;
  vorname: string;
  delivery_turn: number;
  country?: {
    iso2code: string;
    name: string;
  };
  status?: {
    _id: number;
    code: string;
    beschreibung: string;
  };
  zahlart?: {
    _id: number;
    code: string;
    beschreibung: string;
  };
  tours?: {
    _id: number;
    tournr: string;
  };
  servicetours?: {
    _id: number;
    tournr: string;
  };
};
